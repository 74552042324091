<template>
  <div>
    <head-bar title="Checklist toevoegen">
      <template v-slot:left>
        <head-bar-link @click="onBackClick">
          <img src="../../assets/arrow-left.svg" alt="" aria-hidden="true" />
          <span class="visually-hidden">Terug</span>
        </head-bar-link>
      </template>
    </head-bar>

    <app-layout classes="pet-checklist">
      <form class="p-2 p-md-3" action="" @submit.prevent="onSubmit">
        <Errors :errors="serverErrors" />

        <div class="mb-2">
          <label class="mb-1 d-block" for="title">
            Titel
          </label>
          <input
            class="form-control"
            type="text"
            id="title"
            v-model="form.title"
          >
        </div>

        <div class="mb-3">
          <label class="mb-1 d-block" for="checklist-item-0">
            Checklist
          </label>

          <div
            v-for="(checklistItem, checklistItemIndex) in form.checklist"
            :key="checklistItemIndex"
          >
            <div class="d-flex align-items-center">
              <div class="checklist-checkbox">
                <input
                  :id="`checklist-checkbox-${checklistItemIndex}`"
                  type="checkbox"
                  class="checklist-checkbox__input"
                  v-model="checklistItem.is_done"
                  tabindex="-1"
                >
                <label
                  class="checklist-checkbox__label"
                 :for="`checklist-checkbox-${checklistItemIndex}`"
                >
                  <span class="sr-only">Gedaan?</span>
                </label>
              </div>

              <input
                :id="`checklist-item-${checklistItemIndex}`"
                type="text"
                class="form-control"
                v-model="checklistItem.text"
                placeholder="Tekst"
              >

              <button
                type="button"
                class="btn btn--delete"
                @click="() => removeChecklistItem(checklistItemIndex)"
              >
                <svg class="icon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                </svg>
              </button>
            </div>
          </div>

          <button
            type="button"
            class="btn btn--primary mt-2"
            @click="() => addChecklistItem()"
          >
            Item toevoegen
          </button>
        </div>

        <div class="d-flex">
          <button
            class="btn btn--primary"
            type="submit"
            :disabled="petChecklistLoadingState === 'loading'"
          >
            {{
              petChecklistLoadingState === 'loading'
                ? 'Een moment geduld...'
                : 'Checklist opslaan'
            }}
          </button>

          <button
            class="btn ml-auto"
            type="button"
            v-if="form.pet_checklist_id"
            @click="onDeletePetChecklist"
          >
            Verwijderen
          </button>
        </div>
      </form>
    </app-layout>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { useToast } from 'vue-toastification';
import HeadBar from '../../components/HeadBar.vue';
import HeadBarLink from '../../components/HeadBarLink.vue';
import Errors from '../../components/Errors.vue';
import AppLayout from '../AppLayout.vue';
import retrieveErrorsFromResponse from '../../helpers/retrieveErrorsFromResponse';

export default {
  setup() {
    const toast = useToast();
    return { toast };
  },

  components: {
    Errors,
    AppLayout,
    HeadBarLink,
    HeadBar,
  },

  data() {
    return {
      serverErrors: [],
      form: {
        pet_checklist_id: null,
        title: '',
        checklist: [],
      },
    };
  },

  computed: {
    ...mapGetters([
      'petChecklistLoadingState',
    ]),
  },

  mounted() {
    const id = this.$route.params?.id;

    if (typeof id !== 'undefined') {
      // Empty the form to give the user some indication that we're loading the
      // new data.
      this.form.title = '';
      this.form.checklist = [];
      this.form.pet_checklist_id = id;

      this.getPetChecklist(id);
    }
  },

  methods: {
    onBackClick() {
      this.$router.go(-1);
    },

    onSubmit() {
      let promise = null;

      if (this.form.pet_checklist_id) {
        promise = this.$store.dispatch('updatePetChecklist', [this.form.pet_checklist_id, this.form]);
      } else {
        promise = this.$store.dispatch('storePetChecklist', this.form);
      }

      promise
        .then(({ data }) => {
          this.form.pet_checklist_id = data.id;

          this.toast.success('Checklist opgeslagen');
          this.$router.push({ name: 'PetChecklistDetail', params: { id: data.id } });
        })
        .catch((err) => {
          this.serverErrors = !err.response
            ? ['Er ging iets mis. Probeer het later opnieuw.']
            : retrieveErrorsFromResponse(err.response);
        });
    },

    onDeletePetChecklist() {
      if (!this.form.pet_checklist_id) {
        return;
      }

      // eslint-disable-next-line no-alert
      if (!window.confirm('Weet je het zeker?')) {
        return;
      }

      this.$store.dispatch('destroyPetChecklist', this.form.pet_checklist_id)
        .then(() => {
          this.toast.success('Checklist is verwijderd');
          this.$router.push({ name: 'Feed' });
        })
        .catch(() => {
          this.serverErrors = ['Er ging iets mis, probeer het later opnieuw'];
        });
    },

    getPetChecklist(id) {
      this.$store.dispatch('getPetChecklist', id)
        .then(({ data }) => {
          this.form.title = data.title;
          this.form.checklist = data.checklist ? JSON.parse(data.checklist) : [];
        });
    },

    addChecklistItem() {
      this.form.checklist.push({
        text: '',
        is_done: false,
      });
    },

    removeChecklistItem(index) {
      this.form.checklist.splice(index, 1);
    },
  },
};
</script>

<style scoped>
.pet-checklist {
  padding-top: 3.5rem;
}

.checklist-checkbox__input {
  opacity: 0;
  position: absolute;
}

.checklist-checkbox__label {
  width: 2.328125rem;
  height: 2.328125rem;
  cursor: pointer;
  display: inline-block;
  position: relative;
  margin-right: .5rem;
}

.checklist-checkbox__label:before {
  content: '';
  top: 0;
  left: 0;
  position: absolute;
  margin: 0;
  padding: 0;
  width: 2.328125rem;
  height: 2.328125rem;
  border: 1px solid #ced4da;
  background-color: #fff;
  border-radius: 50%;
}

.checklist-checkbox__label:after {
  content: '';
  top: 0;
  left: 0;
  width: 2.328125rem;
  height: 2.328125rem;
  position: absolute;
  background-size: 18px auto;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='%23ced4da' d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z'%3E%3C/path%3E%3C/svg%3E%0A");
}

.checklist-checkbox__input:checked + .checklist-checkbox__label:before {
  background-color: green;
  border-color: green;
}

.checklist-checkbox__input:checked + .checklist-checkbox__label:after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='%23ffffff' d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z'%3E%3C/path%3E%3C/svg%3E%0A");
}

.btn--delete {
  width: 2.328125rem;
  height: 2.328125rem;
  color: #fff;
  padding: 0;
  background-color: red;
  flex-shrink: 0;
  margin-left: 1rem;
}

.btn--delete > .icon {
  width: 1em;
  height: 1em;
}
</style>
