<template>
  <div class="photo-upload" :class="`is-${theme.value}`">
    <div class="photo-upload__head" v-if="!completed || !completedMediaUrl">
      <img
        v-if="activePetType === 'dog'"
        :src="`/img/themes/${theme.value}/photo-upload-dog.svg`"
        alt="Illustratie van telefoon met hond"
      />

      <img
        v-if="activePetType === 'cat'"
        :src="`/img/themes/${theme.value}/photo-upload-cat.svg`"
        alt="Illustratie van telefoon met kat"
      />

      <img
        v-if="theme.value === 'christmas'"
        src="/img/themes/christmas/twig.svg"
        alt="Kersttakje"
        class="photo-upload__head-decoration"
      >
    </div>

    <div class="photo-upload__completed-head" v-if="completed && completedMediaUrl">
      <img
        :src="completedMediaUrl"
        alt=""
        width="640"
        height="640"
        loading="lazy"
      />
    </div>

    <div class="photo-upload__body p-3 text-center">
      <image-upload
        v-if="user"
        v-bind="imageUploadProps"
        type="button"
        @uploaded="(res) => $emit('uploaded', res)"
      />

      <slot />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ImageUpload from './ImageUpload.vue';

export default {
  inject: ['theme'],

  components: {
    ImageUpload,
  },

  props: {
    completed: {
      type: Boolean,
      default: false,
    },

    completedMediaUrl: {
      type: String,
    },

    imageUploadProps: {
      type: Object,
    },
  },

  computed: {
    ...mapGetters([
      'activePetType',
      'user',
    ]),
  },
};
</script>

<style scoped>
.photo-upload {
  border-radius: .875rem;
  overflow: hidden;
}

.photo-upload__head {
  padding-bottom: 56.25%;
  position: relative;
  background-color: #fce3e3;
  border-radius: .875rem .875rem 0 0;
  margin-top: 2rem;
}

.photo-upload__head-decoration {
  top: 0;
  width: 5rem;
  right: 1rem;
  position: absolute;
  transform: rotate(-23deg);
}

.photo-upload__head > img:not(.photo-upload__head-decoration) {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  height: calc(100% + 2rem);
}

.photo-upload__completed-head {
  border: 1px solid #fce3e3;
  border-bottom: 0;
  padding-top: 2rem;
  border-top-right-radius: .875rem;
  border-top-left-radius: .875rem;
}

.photo-upload__completed-head img {
  width: 7.5rem;
  height: auto;
  display: block;
  margin: 0 auto;
  border-radius: .875rem;
}

.photo-upload__body {
  border: 1px solid #fce3e3;
  border-top: 0;
  border-bottom-right-radius: .875rem;
  border-bottom-left-radius: .875rem;
}

.photo-upload__body .image-upload {
  width: auto;
}

.photo-upload__body .image-upload__button {
  padding: 1rem 1.75rem 1rem 3.5rem;
  font-size: 1.0625rem;
  position: relative;
}

.photo-upload__body .image-upload__button:before {
  content: '';
  position: absolute;
  top: .75rem;
  left: 1.125rem;
  width: 1.425rem;
  height: 1.425rem;
  background: url(../assets/icon-camera.svg) no-repeat center center;
  background-size: 100% auto;
}

/* Christmas theme */
.is-christmas .photo-upload__head {
  background-image: url('/img/themes/christmas/snow.svg');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
}
</style>
