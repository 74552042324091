<template>
  <div>
    <head-bar title="Notitie toevoegen">
      <template v-slot:left>
        <head-bar-link @click="onBackClick">
          <img src="../../assets/arrow-left.svg" alt="" aria-hidden="true" />
          <span class="visually-hidden">Terug</span>
        </head-bar-link>
      </template>
    </head-bar>

    <app-layout classes="pet-note">
      <form class="p-2 p-md-3" action="" @submit.prevent="onSubmit">
        <Errors :errors="serverErrors" />

        <div>
          <photo-upload
            :completed="!!completedMediaUrl"
            :completed-media-url="completedMediaUrl"
            :image-upload-props="{
              userId: user?.user.id,
              petNoteId: null,
              isTemporary: true,
            }"
            @uploaded="(res) => onImageUploaded(res)"
          />
        </div>

        <div class="mb-2">
          <input
            class="custom-checkbox"
            type="checkbox"
            id="is_milestone"
            v-model="form.is_milestone"
          >
          <label for="is_milestone" class="mb-0">
            Is mijlpaal
          </label>
        </div>

        <div class="mb-2">
          <label class="mb-1 d-block" for="date">
            Datum
          </label>
          <input
            class="form-control"
            type="date"
            id="date"
            v-model="form.date"
            :max="dateMax"
          >
        </div>

        <div class="mb-2">
          <label class="mb-1 d-block" for="note">
            Notitie
          </label>
          <textarea
            class="form-control"
            type="number"
            id="note"
            rows="6"
            v-model="form.note"
            maxlength="10000"
          />
        </div>

        <div class="d-flex">
          <button
            class="btn btn--primary"
            type="submit"
            :disabled="petNoteLoadingState === 'loading'"
          >
            {{
              petNoteLoadingState === 'loading'
                ? 'Een moment geduld...'
                : 'Notitie opslaan'
            }}
          </button>

          <button
            class="btn ml-auto"
            type="button"
            v-if="form.pet_note_id"
            @click="onDeletePetNote"
          >
            Verwijderen
          </button>
        </div>
      </form>
    </app-layout>
  </div>
</template>

<script>
import { format, parseISO } from 'date-fns';
import { mapGetters } from 'vuex';
import { useToast } from 'vue-toastification';
import HeadBar from '../../components/HeadBar.vue';
import HeadBarLink from '../../components/HeadBarLink.vue';
import Errors from '../../components/Errors.vue';
import AppLayout from '../AppLayout.vue';
import PhotoUpload from '../../components/PhotoUpload.vue';
import retrieveErrorsFromResponse from '../../helpers/retrieveErrorsFromResponse';

export default {
  setup() {
    const toast = useToast();
    return { toast };
  },

  components: {
    Errors,
    AppLayout,
    HeadBarLink,
    HeadBar,
    PhotoUpload,
  },

  data() {
    return {
      dateMax: format(new Date(), 'yyyy-MM-dd'),
      serverErrors: [],
      form: {
        pet_note_id: null,
        date: format(new Date(), 'yyyy-MM-dd'),
        note: '',
        is_milestone: false,
        media_item_id: null,
        media_item_path: null,
      },
    };
  },

  computed: {
    ...mapGetters([
      'user',
      'petNoteLoadingState',
    ]),

    completedMediaUrl() {
      return this.form.media_item_path;
    },
  },

  mounted() {
    const id = this.$route.params?.id;

    if (typeof id !== 'undefined') {
      // Empty the form to give the user some indication that we're loading the
      // new data.
      this.form.date = '';
      this.form.note = '';
      this.form.is_milestone = false;
      this.form.media_item_id = null;
      this.form.media_item_path = null;
      this.form.pet_note_id = id;

      this.getPetNote(id);
    }
  },

  methods: {
    onBackClick() {
      this.$router.go(-1);
    },

    onSubmit() {
      let promise = null;

      if (this.form.pet_note_id) {
        promise = this.$store.dispatch('updatePetNote', [this.form.pet_note_id, this.form]);
      } else {
        promise = this.$store.dispatch('storePetNote', this.form);
      }

      promise
        .then(({ data }) => {
          this.form.pet_note_id = data.id;

          this.toast.success('Notitie opgeslagen');
          this.$router.push({ name: 'PetNoteDetail', params: { id: data.id } });
        })
        .catch((err) => {
          this.serverErrors = !err.response
            ? ['Er ging iets mis. Probeer het later opnieuw.']
            : retrieveErrorsFromResponse(err.response);
        });
    },

    onDeletePetNote() {
      if (!this.form.pet_note_id) {
        return;
      }

      // eslint-disable-next-line no-alert
      if (!window.confirm('Weet je het zeker?')) {
        return;
      }

      this.$store.dispatch('destroyPetNote', this.form.pet_note_id)
        .then(() => {
          this.toast.success('Notitie is verwijderd');
          this.$router.push({ name: 'Feed' });
        })
        .catch(() => {
          this.serverErrors = ['Er ging iets mis, probeer het later opnieuw'];
        });
    },

    getPetNote(id) {
      this.$store.dispatch('getPetNote', id)
        .then(({ data }) => {
          this.form.note = data.note;
          this.form.date = format(parseISO(data.created_at), 'yyyy-MM-dd');
          this.form.is_milestone = data.is_milestone;
          this.form.media_item_id = data?.media.id;
          this.form.media_item_path = data?.media.default_url;
        });
    },

    onImageUploaded(res) {
      this.form.media_item_id = res.id;
      this.form.media_item_path = res.default_url;
    },
  },
};
</script>

<style scoped>
.pet-note {
  padding-top: 3.5rem;
}
</style>
